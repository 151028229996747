// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_@rspack+core@1.0.0_@swc+helpers@0.5.12__webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_@rspack+core@1.0.0_@swc+helpers@0.5.12__webpack@5.94.0_@swc+core@1.7.22_@swc+helpers@0.5.12__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    background-color: #ffffff;
    color: #000;
}

.sidebar {
    overflow: auto;
    color: rgb(119, 119, 119);
    background: linear-gradient(
            rgb(249, 248, 247),
            rgb(251, 250, 248) 46px,
            rgb(251, 251, 249) 120px,
            rgb(248, 247, 245) 35%,
            rgb(249, 248, 246)
    );
}

.sidebar__highlights {
    list-style: "none";
    padding: 0;
}

.highlight__location {
    margin-top: 0.5rem;
    text-align: right;
    font-size: 10px;
}

.highlight__image {
    overflow: auto;
    max-width: 300px;
    border: 1px dashed;
}

.sidebar__highlight {
    padding: 1rem;
    cursor: pointer;
    transition: background 140ms ease-in;
    border-bottom: 1px solid rgb(119, 119, 119);
}

.sidebar__highlight:hover {
    background: rgba(58, 56, 52, 0.08);
}

a {
    color: #d35400;
}

blockquote {
    padding: 0;
    margin: 0;
    quotes: "\\201c" "\\201d";
}

blockquote:before {
    content: open-quote;
}

blockquote:after {
    content: close-quote;
}
`, "",{"version":3,"sources":["webpack://./src/style/App.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT;;cAEU;IACV,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB;;;;;;KAMC;AACL;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,oCAAoC;IACpC,2CAA2C;AAC/C;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":["body {\n    margin: 0;\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n    background-color: #ffffff;\n    color: #000;\n}\n\n.sidebar {\n    overflow: auto;\n    color: rgb(119, 119, 119);\n    background: linear-gradient(\n            rgb(249, 248, 247),\n            rgb(251, 250, 248) 46px,\n            rgb(251, 251, 249) 120px,\n            rgb(248, 247, 245) 35%,\n            rgb(249, 248, 246)\n    );\n}\n\n.sidebar__highlights {\n    list-style: \"none\";\n    padding: 0;\n}\n\n.highlight__location {\n    margin-top: 0.5rem;\n    text-align: right;\n    font-size: 10px;\n}\n\n.highlight__image {\n    overflow: auto;\n    max-width: 300px;\n    border: 1px dashed;\n}\n\n.sidebar__highlight {\n    padding: 1rem;\n    cursor: pointer;\n    transition: background 140ms ease-in;\n    border-bottom: 1px solid rgb(119, 119, 119);\n}\n\n.sidebar__highlight:hover {\n    background: rgba(58, 56, 52, 0.08);\n}\n\na {\n    color: #d35400;\n}\n\nblockquote {\n    padding: 0;\n    margin: 0;\n    quotes: \"\\201c\" \"\\201d\";\n}\n\nblockquote:before {\n    content: open-quote;\n}\n\nblockquote:after {\n    content: close-quote;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
